<template>
  <div
    class="
      h-screen
      bg-white
      relative
      flex flex-col
      space-y-10
      justify-center
      items-center
    "
  >
    <div class="bg-slate-200 md:shadow-xl shadow-none rounded p-6 w-96">
      <h1 class="text-3xl font-bold leading-normal text-black">
        Welcome back!
      </h1>
      <p class="text-sm leading-normal text-black">Sign in to continue</p>
      <p class="text-red-500">{{ errorMessage }}</p>
      <p class="text-red-500">{{ $route.query.error }}</p>
      <form @submit.prevent="login" class="space-y-5 mt-5">
        <div class="mb-4 relative">
          <input
            v-model="email"
            class="
              w-full
              rounded
              px-3
              border border-gray-500
              pt-5
              pb-2
              focus:outline-none
              input
              active:outline-none
              bg-white
              text-black
            "
            type="text"
            required
            autofocus
          />
          <label
            class="
              label
              absolute
              mb-0
              -mt-2
              pt-4
              pl-3
              leading-tighter
              text-gray-500 text-base
              mt-2
              cursor-text
            "
            >Email</label
          >
        </div>

        <div
          class="
            relative
            flex
            items-center
            border border-gray-500
            focus:ring focus:border-blue-500
            rounded
          "
        >
          <input
            v-model="password"
            id="password"
            class="
              w-full
              rounded
              px-3
              pt-5
              outline-none
              pb-2
              focus:outline-none
              active:outline-none
              input
              active:border-blue-500
              bg-white
              text-black
            "
            type="password"
            required
          />
          <label
            class="
              label
              absolute
              mb-0
              -mt-2
              pt-4
              pl-3
              leading-tighter
              text-gray-500 text-base
              mt-2
              cursor-text
            "
            >Password</label
          >
        </div>

        <button
          class="
            w-full
            text-center
            bg-custom-blue
            hover:bg-blue-700
            rounded-full
            text-white
            py-3
            font-medium
          "
        >
          Sign in
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    login() {
      // to implement login from api
      if (this.email != "" || this.password != "") {
        this.axios
          .post("login", {
            headers: {
              "Content-Type": "application/json",
            },
            email: this.email,
            password: this.password,
          })
          .then(async (response) => {
            if (response.status == 201) {
              this.errorMessage = response.data["message"][0];
            } else if (response.status == 200) {
              await this.$store.dispatch("createUserSession", response.data);
              this.$router.push("ManageBanner");
            }
          });
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: #f8f8fb;
}

.input {
  transition: border 0.2s ease-in-out;
  min-width: 280px;
}

.input:focus + .label,
.input:active + .label,
.input.filled + .label {
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  top: -0.3rem;
  color: #6b7280;
}

.label {
  transition: all 0.2s ease-out;
  top: 0.4rem;
  left: 0;
}

.bg-custom-blue {
  background-color: #556ee6;
}
</style>
